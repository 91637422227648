<!--
 * @Author: wxb
 * @Date: 2021-07-15 09:14:20
 * @LastEditTime: 2021-08-11 17:28:09
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\views\Subject\park.vue
-->
<template>
  <div class="containner">
    <headerNav :activeIdx='6'></headerNav>
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/special' }">专题</el-breadcrumb-item>
        <el-breadcrumb-item>园区专题</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="park-content"
           v-show="canSee">
        <div class="profile-map">
          <div class="profile-map-tools">
            <div class="profile-map-tools-item"
                 v-show="cityBack">
              <a class="profile-map-tools-back"
                 @click="handleCityBack">
                <el-image :src="url"
                          fit="cover"></el-image>
              </a>
            </div>
          </div>
          <div class="profile-map-legends">
            <el-checkbox-group v-model="checkList"
                               @change="handleCheckedLegendsChange"
                               :min="1">
              <el-checkbox :label="item"
                           v-for="(item,index) in legendsLst"
                           :key="index"></el-checkbox>

            </el-checkbox-group>
          </div>
          <div id="myChartChina"
               class="profile-map-content"></div>
        </div>

        <div class="park-statictis">
          <div class="park-statictis-item">
            <div class="park-statictis-item-title">园区信息</div>
            <div class="park-statictis-item-content">
              <el-tabs v-model="activeName">

                <el-tab-pane label="园区列表"
                             name="first">
                  <div class="park-search">
                    <el-form label-position="right"
                             label-width="80px"
                             :model="parkSearchForm"
                             @submit.native.prevent>
                      <el-row :gutter="20">
                        <el-col :span="10">
                          <el-form-item label="园区名称">
                            <el-input v-model="parkSearchForm.parkName"
                                      @keyup.enter.native="handleSearchPark"
                                      clearable></el-input>
                          </el-form-item>
                        </el-col>
                        <el-col :span="10">
                          <div class="grid-content bg-purple">
                            <el-form-item label="园区类型">
                              <el-select v-model="parkSearchForm.parkType"
                                         placeholder="请选择园区类型"
                                         clearable
                                         @change="handleSearchPark">
                                <el-option :label="item.DICTIONARYITEM_ITEMNAME"
                                           :value="item.DICTIONARYITEM_ITEMNAME"
                                           v-for="(item,index) in column4ParkType"
                                           :key="index"></el-option>
                              </el-select>
                            </el-form-item>
                          </div>
                        </el-col>
                        <el-col :span="4">
                          <el-button type="primary"
                                     @click="handleSearchPark">搜索</el-button>
                        </el-col>
                      </el-row>
                    </el-form>
                  </div>
                  <el-table :data="tableData4park"
                            stripe
                            border
                            style="width: 100%"
                            ref="parkList"
                            @sort-change="handleSortChanger4Park">
                    <template slot="empty">
                      <el-empty description="哎呀，未查询到相关信息"></el-empty>
                    </template>
                    <el-table-column prop="parkName"
                                     sortable='custom'
                                     label="园区名称"
                                     :show-overflow-tooltip="true">
                      <template slot-scope="scope">
                        <el-button type="text"
                                   size="small"
                                   @click="handleClickPark(scope.row)">{{scope.row.parkName}}</el-button>
                      </template>
                    </el-table-column>
                    <el-table-column prop="parkTypeName"
                                     sortable='custom'
                                     label="园区类型">
                    </el-table-column>
                    <el-table-column prop="provinceName"
                                     sortable='custom'
                                     label="省份">
                    </el-table-column>
                    <el-table-column prop="cityName"
                                     sortable='custom'
                                     label="城市">
                    </el-table-column>
                  </el-table>
                  <div class="page-panel">
                    <el-pagination background
                                   layout="prev, slot, next"
                                   :total="parkListCount"
                                   :current-page.sync="currentPage4Park"
                                   @current-change="handleCurrentChange4Park"
                                   :hide-on-single-page="true">
                      <span class="current-page">{{currentPage4Park}}</span>
                    </el-pagination>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="荣誉颁发情况"
                             name="second">
                  <div class="park-search">
                    <el-form label-position="right"
                             label-width="80px"
                             @submit.native.prevent
                             :model="honorSearchForm">
                      <el-row :gutter="20">
                        <el-col :span="20">
                          <el-form-item label="荣誉名称">
                            <el-input v-model="honorSearchForm.honorName"
                                      clearable
                                      @keyup.enter.native="handleSearchHonor"></el-input>
                          </el-form-item>
                        </el-col>
                        <el-col :span="4">
                          <el-button type="primary"
                                     @click="handleSearchHonor">搜索</el-button>
                        </el-col>
                      </el-row>
                    </el-form>
                  </div>
                  <el-table :data="honorList"
                            stripe
                            border
                            style="width: 100%"
                            ref="honorList"
                            @sort-change="handleSortChanger4Honor">
                    <template slot="empty">
                      <el-empty description="哎呀，未查询到相关信息"></el-empty>
                    </template>
                    <el-table-column prop="honorName"
                                     sortable='custom'
                                     label="荣誉名称">
                      <template slot-scope="scope">
                        <el-link :href="scope.row.website"
                                 target="_blank"
                                 type="primary"
                                 :title="scope.row.honorName">{{scope.row.honorName}}</el-link>
                      </template>
                    </el-table-column>
                    <el-table-column prop="nums"
                                     sortable='custom'
                                     width="120"
                                     label="获得数量">
                    </el-table-column>
                  </el-table>
                  <div class="page-panel">
                    <el-pagination background
                                   layout="prev, slot, next"
                                   :current-page.sync="currentPage4Honor"
                                   :total="honorListCount"
                                   @current-change="handleCurrentChange4Honor"
                                   :hide-on-single-page="true">
                      <span class="current-page">{{currentPage4Honor}}</span>
                    </el-pagination>
                  </div>
                </el-tab-pane>
              </el-tabs>

            </div>
          </div>

        </div>
      </div>
      <viporlogin bgStr="5"
                  :type="viporlogin"
                  v-show="!canSee"></viporlogin>
    </div>
    <footerNav></footerNav>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import headerNav from '@/components/headerNavBlue'
import footerNav from '@/components/footerNav'
import { mapStatistics, getParkList, getHonorList } from '@/api/subject'
import { getListDictionary } from '@/api/dictionary'
import { authenticate } from '@/utils/authenticate'
import { getToken } from '@/utils/auth'
import viporlogin from '@/components/viporlogin'
import { mapGetters } from 'vuex'
export default {
  name: 'park',
  components: {
    headerNav,
    footerNav,
    viporlogin
  },
  data() {
    return {
      viporlogin: 'login',
      canSee: true,
      typeOrhonor: true,
      url: require('@/assets/map.png'),
      cityBack: false,
      activeName: 'first',
      mapName: 'china',
      mapJson: {},
      tableData4park: [{}],
      column4ParkType: [],
      honorList: [{}],
      parkSearchForm: {
        mapType: '',
        province: '',
        city: '',
        parkName: '',
        parkType: '',
        columnName: '',
        order: '',
        start: 0,
        limit: 10
      },
      parkListCount: 0,
      currentPage4Park: 1,
      honorSearchForm: {
        mapType: '',
        province: '',
        honorName: '',
        columnName: '',
        order: '',
        start: 0,
        limit: 10
      },
      honorListCount: 0,
      currentPage4Honor: 1,
      checkList: [],
      legendsLst: []
    }
  },
  computed: {
    ...mapGetters({
      memberType: 'memberType'
    })
  },
  methods: {
    authCheck() {
      const authObj = authenticate('park', this.memberType)
      if (authObj.flag) {
        // 初始化页面
        this.initPage()
        this.canSee = true
      } else {
        // 根据msg值不同展示不同的提示信息
        this.canSee = false
        this.viporlogin = authObj.msg
      }
    },
    // 初始化页面
    initPage() {
      this.mapJson = require('@/map/chinaMap.json')
      this.mapName = 'china'
      this.myChartChina = echarts.init(document.getElementById('myChartChina'))
      this.initMap()
    },
    initMap() {
      this.parkSearchForm.parkName = ''
      this.parkSearchForm.parkType = ''
      this.parkSearchForm.city = ''
      this.parkSearchForm.columnName = ''
      this.parkSearchForm.order = ''
      this.parkSearchForm.start = 0
      this.currentPage4Park = 1
      this.honorSearchForm.honorName = ''
      this.honorSearchForm.columnName = ''
      this.honorSearchForm.order = ''
      this.honorSearchForm.start = 0
      this.currentPage4Honor = 1
      if (this.mapName === 'china') {
        // typeOrhonor true：园区类型 false：荣誉奖项
        this.mapParams = {
          mapType: 'china',
          statisticalType: this.typeOrhonor ? 'type' : 'honor'
        }

        this.parkSearchForm.mapType = 'china'
        this.honorSearchForm.mapType = 'china'
      } else {
        this.mapParams = {
          mapType: 'province',
          province: this.mapName,
          statisticalType: this.typeOrhonor ? 'type' : 'honor'
        }

        this.parkSearchForm.mapType = 'province'
        this.parkSearchForm.province = this.mapName
        this.honorSearchForm.mapType = 'province'
        this.honorSearchForm.province = this.mapName
      }
      // 地图信息
      mapStatistics(this.mapParams).then(res => {
        if (res.ok) {
          this.drawMap(res.lst)
        }
      }).catch(err => {
        console.log(err)
      })

      // 获取园区列表
      this.$refs.parkList.clearSort()
      this.getParkList()
      // 获取荣誉列表
      this.$refs.honorList.clearSort()
      this.getHonorList()
    },
    // 画园区类型或者园区荣誉地图
    drawMap(list) {
      this.legendsLst = []
      this.checkList = []
      echarts.registerMap(this.mapName, this.mapJson)
      const zoom = this.cityBack ? 1 : 1.23
      const series = []
      for (const item of list) {
        this.legendsLst.push(item.ctgName)
        this.checkList.push(item.ctgName)
        const obj = {
          name: item.ctgName,
          type: 'map',
          map: this.mapName,
          showLegendSymbol: false,
          zoom,
          roam: true, // 不开启缩放和平移
          data: item.List,
          scaleLimit: {
            min: 1,
            max: 10
          },
          label: {
            show: true,
            position: 'top',
            distance: 5,
            formatter: '{b}'
          }
        }
        series.push(obj)
      }
      const option = {
        legend: {
          data: this.legendsLst,
          type: 'scroll',
          show: false,
          orient: 'vertical',
          left: 'right',
          width: 'auto'
        },
        roam: true,
        lebel: {
          show: true
        },
        tooltip: {
          formatter(params) {
            const name = params.name
            let html = `${name}<br>`
            for (const iterator of series) {
              const dataList = iterator.data
              const idx = dataList.findIndex(item => item.name === name)
              if (idx > -1) {
                html += iterator.name + '：' + dataList[idx].value + '<br>'
              }
            }
            return html
          } // 数据格式化
        },
        visualMap: {
          min: 0,
          max: 150,
          left: 'left',
          top: 'middle',
          text: ['高', '低'], // 取值范围的文字
          inRange: {
            color: ['#e0ffff', '#006edd'] // 取值范围的颜色
          },
          show: true, // 图注
          realtime: false,
          calculable: true
        },
        mapValueCalculation: 'sum',
        series
      }
      if (this.mapName === '海南省') {
        option.series.forEach(element => {
          element.center = [109.844902, 19.0392]
          element.layoutCenter = ['50%', '50%']
          element.layoutSize = '600%'
        })
      } else {
        option.series.forEach(element => {
          element.center = undefined
          element.layoutCenter = undefined
          element.layoutSize = undefined
        })
      }
      this.myChartChina.setOption(option)

      this.myChartChina.on('click', params => {
        if (echarts.getMap(this.mapName).geoJson.features[0].properties.level === 'province') {
          const name = params.name
          this.cityBack = true
          this.mapJson = require(`@/map/${name}.json`)
          this.mapName = name
          this.myChartChina.dispose()
          this.myChartChina = echarts.init(document.getElementById('myChartChina'))
          this.initMap()
        } else if (echarts.getMap(this.mapName).geoJson.features[0].properties.level === 'city') {
          // 城市只需要更改园区列表即可
          const name = params.name
          if (this.parkSearchForm.city === name) {
            this.parkSearchForm.city = ''
          } else {
            this.parkSearchForm.city = name
          }
          this.parkSearchForm.start = 0
          this.currentPage4Park = 1
          this.getParkList()
        }
      })

      this.myChartChina.on('legendselectchanged', params => {
        const option = this.myChartChina.getOption()
        const selectvalue = Object.values(params.selected)
        if (selectvalue.every(val => !val)) {
          option.legend[0].selected[params.name] = true
        }
        this.myChartChina.setOption(option)
      })
    },
    // 获取园区列表
    getParkList() {
      getParkList(this.parkSearchForm).then(res => {
        if (res.ok) {
          this.tableData4park = res.lst
          if (res.cnt > 100) {
            this.parkListCount = 100
          } else {
            this.parkListCount = res.cnt
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 园区搜索事件
    handleSearchPark() {
      this.currentPage4Park = 1
      this.parkSearchForm.start = 0
      this.getParkList()
    },
    // 园区分页点击事件
    handleCurrentChange4Park(val) {
      this.parkSearchForm.start = (val - 1) * this.parkSearchForm.limit
      this.getParkList()
    },
    // 园区排序触发
    handleSortChanger4Park(prop) {
      this.parkSearchForm.start = 0
      this.currentPage4Park = 1
      if (prop.order) {
        this.parkSearchForm.columnName = prop.prop
        this.parkSearchForm.order = prop.order === 'ascending' ? 'asc' : 'desc'
      } else {
        this.parkSearchForm.columnName = ''
        this.parkSearchForm.order = ''
      }
      this.getParkList()
    },
    // 荣誉搜索
    getHonorList() {
      getHonorList(this.honorSearchForm).then(res => {
        if (res.ok) {
          this.honorList = res.lst
          if (res.cnt > 100) {
            this.honorListCount = 100
          } else {
            this.honorListCount = res.cnt
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 搜索荣誉
    handleSearchHonor() {
      this.currentPage4Honor = 1
      this.honorSearchForm.start = 0
      this.getHonorList()
    },
    // 荣誉分页点击事件
    handleCurrentChange4Honor(val) {
      this.honorSearchForm.start = (val - 1) * this.honorSearchForm.limit
      this.getHonorList()
    },
    // 荣誉排序触发
    handleSortChanger4Honor(prop) {
      this.honorSearchForm.start = 0
      this.currentPage4Honor = 1
      if (prop.order) {
        this.honorSearchForm.columnName = prop.prop
        this.honorSearchForm.order = prop.order === 'ascending' ? 'asc' : 'desc'
      } else {
        this.honorSearchForm.columnName = ''
        this.honorSearchForm.order = ''
      }
      this.getHonorList()
    },
    // 返回全国地图
    handleCityBack() {
      this.cityBack = false
      this.mapJson = require('@/map/chinaMap.json')
      this.mapName = 'china'
      this.myChartChina.dispose()
      this.myChartChina = echarts.init(document.getElementById('myChartChina'))
      this.initMap()
    },
    // 地图legends改变
    handleCheckedLegendsChange(value) {
      const option = this.myChartChina.getOption()
      const selectLegend = {}
      for (const item of this.legendsLst) {
        const idx = value.findIndex(it => it === item)
        selectLegend[item] = idx !== -1
      }
      option.legend[0].selected = selectLegend
      this.myChartChina.setOption(option)
    },
    // 园区列表点击事件
    handleClickPark(row) {
      const id = row.id
      const routeData = this.$router.resolve({
        name: 'parkDetail',
        params: {
          id
        }
      })
      window.open(routeData.href, '_blank')
    }
  },
  mounted() {
    getListDictionary({ code: 'TYPEGARDEN' }).then(res => {
      if (res.ok) {
        this.column4ParkType = res.lst
      }
    })
    if (getToken()) {
      if (this.memberType === 'tourist') {
        this.$store.dispatch('getMemberInfo').then(res => {
          this.authCheck()
        })
      } else {
        this.authCheck()
      }
    } else {
      this.authCheck()
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 144rem;
  margin: 0 auto;
}

.el-breadcrumb {
  height: 5.4rem;
  line-height: 5.4rem;
}

.park-content {
  display: flex;
  .profile-map {
    width: 750px;
    height: calc(100vh - 180px);
    min-height: 1000px;
    position: relative;
    &-tools {
      position: absolute;
      left: 0;
      z-index: 999;
      display: flex;
      align-items: center;
      &-item {
        margin-left: 5px;
      }

      &-back {
        cursor: pointer;
      }
    }

    &-legends {
      position: absolute;
      right: 11px;
      top: 0;

      .el-checkbox {
        display: block;
      }
    }

    &-content {
      width: 100%;
      height: 100%;
      // padding-top: 100px;
    }
  }

  .park-statictis {
    flex: 1;
    &-item {
      &-title {
        color: #398ce2;
        font-size: 16px;
        padding: 5px;
        font-weight: 700;
      }

      &-content {
        border: 1px solid #ddd;
        border-top: 2px solid #398ce2;
        padding: 5px;
        min-height: 500px;
        .page-panel {
          margin-top: 5px;
          text-align: right;
        }
      }
    }
  }
}
</style>
